* {
  margin: 0;
  padding: 0;
}

HTML, BODY {
  height: 100%;
  min-height: 100%;
  font-family: Arial, sans-serif;
}

BODY, TD, TH {
  color: #159dc3;
}

ul {
  list-style: none;
}

A {
  text-decoration: none;
}

.b {
  font-weight: bold;
}

.u {
  text-decoration: underline;
}

#nav {
  z-index: 99999999;
  background-color: #000000e6;
  width: 100%;
  height: 1200px;
  padding-top: 90px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.dropdown-content {
  transition-duration: .2s;
  display: none;
}

.show {
  opacity: 1;
  transition-duration: .2s;
  display: block;
}

.hide {
  opacity: 0;
  transition-duration: .2s;
  display: none;
}

#nav > li {
  opacity: 0;
  padding-left: 2%;
  font-size: 1.2em;
}

#nav li.uppr0, #nav li.uppr1, #nav li.uppr2, #nav li.uppr3, #nav li.uppr4 {
  opacity: 1;
  display: block;
}

#nav li a:not(.sub-mobile-menu a) {
  color: #adadad;
  text-align: left;
  -o-transition: all .3s ease-in-out;
  padding: 30px 0 30px calc(5% + 50px);
  transition: all .3s ease-in-out;
  display: block;
}

#nav li a:not(.sub-mobile-menu a):hover {
  color: #fff;
}

#nav li:not(.sub-mobile-menu li, li.log-out, li.log-out-mb):before {
  content: "";
  z-index: -1;
  -o-transition: all .3s ease-in-out;
  transform-origin: 17px 38px;
  background: url("icon_across.43944715.svg") 0% bottom no-repeat;
  width: 100%;
  height: 55px;
  transition: all .3s ease-in-out;
  position: absolute;
  left: 7%;
}

#nav li.acti a {
  color: #fff;
}

#nav li.acti:before {
  z-index: -1;
  background: url("icon_across_hover.3e3aab35.svg") 0% bottom no-repeat;
  width: 100%;
  height: 55px;
  padding-left: calc(5% + 50px);
  position: absolute;
}

#nav li:hover:before {
  transform-origin: 17px 38px;
  transform: rotate(360deg);
}

#nav li:hover a {
  color: #fff;
}

.log-out {
  float: right;
  color: #fff;
  cursor: pointer;
  letter-spacing: .02em;
  background: #159dc3;
  border-left: 1px solid #252525;
  border-radius: 10px;
  margin-top: 12px;
  margin-left: 2em;
  padding: 1em 1.5em .9em;
  position: absolute;
  right: 2%;
  overflow: hidden;
}

.log-out-mb {
  float: left;
  cursor: pointer;
  margin-top: 210px;
  padding: 0 1.5em;
  position: absolute;
  left: 5%;
  overflow: hidden;
}

.nav-wrap li.log-out-mb:hover {
  color: #fff;
}

#menu_mobile {
  padding-right: 30px;
}

.margintop {
  margin-top: 170px;
}

.menu-icon {
  float: right;
  text-align: right;
  cursor: pointer;
  z-index: 2147483647;
  padding: 17px 0;
  position: absolute;
  right: 2%;
}

.menu-icon span {
  color: #5a5a5a;
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  -o-transform-origin: 100% 0;
  transform-origin: 100% 70%;
  margin: -50px 15px 0 0;
  font-size: .7em;
  font-weight: normal;
  display: block;
  transform: rotate(270deg);
}

.anim0 {
  transition: transform .2s;
  transform: rotate(45deg);
}

.anim1 {
  opacity: 0;
}

.anim2 {
  transition: transform .2s;
  transform: rotate(-45deg);
}

.cls-1, .cls-2, .cls-3 {
  fill: #fff;
}

.cls-1, .cls-2, .cls-3, .cls-4 {
  fill-rule: evenodd;
}

.cls-4 {
  fill: #45abc5;
}

.cls-1, .cls-3 {
  transition: transform .2s;
}

.cls-1 {
  transform-origin: 16px 15px;
}

.cls-2 {
  transition: opacity .1s;
}

.cls-3 {
  transform-origin: 13.5px 22px;
}

.nav-wrap {
  float: right;
  background: #313131;
  width: 100vw;
  height: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.small {
  font-size: .8em;
}

#ahead {
  text-align: center;
  color: #159dc3;
  z-index: 9996;
  background-color: #000000d9;
  width: 100%;
  height: 72px;
  font-weight: bold;
  display: block;
  position: fixed;
  overflow: hidden;
}

.top_head {
  width: 10%;
}

.top_head li:not(.log-out) {
  float: left;
  line-height: 72px;
  overflow: hidden;
}

.top_head li.logotype {
  text-align: left;
  float: left;
  z-index: 2147483647;
  border: none;
  width: 240px;
  padding-right: 20px;
  font-size: 2em;
  overflow: hidden;
}

.top_head li.logotype a {
  float: left;
  color: #fff;
  padding: 0;
  line-height: 52px;
}

.top_head li.logotype a:hover {
  background: none;
  border: none;
}

.top_head li.logotype a.active {
  background: none;
}

.top_head a {
  color: #fff;
  height: 80px;
  padding: 0 25px;
  transition: all .4s ease-out;
  display: inline-block;
}

.top_head svg {
  margin: 14px 10px 0 0;
  display: inline;
}

.top_head span {
  float: right;
  vertical-align: top;
  display: block;
}

.top_head p {
  color: #919191;
  margin: 0;
  font-size: .3em;
  line-height: 0;
}

#ahead p {
  line-height: 1px;
}

noindex:-o-prefocus {
  line-height: 1px;
}

#line {
  background-color: #3a3a3a;
  height: 6px;
  display: block;
  position: absolute;
}

.top_head .line {
  z-index: 99999;
  background-color: #fff;
  width: 100px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.submenu a:not(.active) {
  color: #93a2a6;
  border: 0;
  width: 0;
  margin: 0;
  padding: 0;
  transition: all .5s ease-out;
  position: relative;
  top: 0;
  overflow: hidden;
}

.top_head .hvsubmenu:hover span .submenu a {
  color: #fff;
  background: #0c4553;
  width: 87px;
  font-weight: normal;
  transition: all .4s ease-out;
}

.top_head .hvsubmenu:hover span .submenu a.active, .top_head a:hover {
  color: #fff;
  background: #1d88a3;
  transition: all .5s ease-out;
}

.top_head .active {
  color: #fff;
  background: #159dc3;
  transition: all .5s ease-out;
}

.top_head .hvsubmenu span .submenu a.active {
  color: #ddd;
  background: #159dc3;
  width: 87px;
  padding: 0;
  transition: all .5s ease-out;
}

.top_head .submenu li {
  border: 0;
  width: 87px;
  padding: 0;
  transition: all .5s ease-out;
}

.top_head .submenu a.active {
  width: 87px;
  padding: 0;
  transition: all .5s ease-out;
  overflow: hidden;
}

.top_head .hvsubmenu span .submenu a:hover {
  color: #fff;
  background: #1d88a3;
}

.top_head .hvsubmenu:hover a.actv {
  background: #1d88a3;
  height: 72px;
}

.sub-mobile-menu {
  float: left;
  text-align: left;
  margin-left: 10%;
  display: table;
  position: relative;
}

.sub-mobile-menu li {
  border-top: 3px solid #fff;
  margin-left: .7em;
  padding: 1em 3em 1em 0;
}

.sub-mobile-menu li:before {
  content: "";
  width: 18px;
  height: 20px;
  position: absolute;
}

#ahead .sub-mobile-menu a {
  color: #fff;
  background: url("v-icon.83982688.svg") 0 1.6rem no-repeat;
  margin-left: -30px;
  padding-left: 30px;
  font-size: .9em;
  font-weight: normal;
  line-height: normal;
  transition: all .1s ease-out;
  display: block;
}

#ahead .sub-mobile-menu a:hover {
  color: #159dc3;
  background: url("v-icon-hover.1d6a5619.svg") 0 1.6rem no-repeat;
  transition: all .1s ease-out;
}

#ahead .sub-mobile-menu a.active {
  color: #159dc3;
  background: url("v-icon-hover.1d6a5619.svg") 0 1.6rem no-repeat;
}

#logo {
  z-index: 998;
  text-align: center;
  width: 150px;
  height: 150px;
  display: block;
  position: absolute;
  top: 110px;
  left: 12%;
}

.slogan {
  z-index: 998;
  position: absolute;
  bottom: 90%;
  left: calc(50% - 160px);
}

.slogan h1 {
  color: #fff;
  letter-spacing: .04em;
}

.slogan h2 {
  color: #000;
  letter-spacing: .28em;
  line-height: 25px;
}

.slogan p {
  color: #f0f0f0;
  letter-spacing: .01em;
  font-family: CenturyGothicRegular, Arial, sans-serif;
  font-size: 1em;
  line-height: 2em;
}

.App-h1 h1 {
  color: #000;
  font-size: 8em;
  font-weight: bold;
}

div.App-h1 {
  padding-top: 100px;
}

.als-1 {
  fill: #45abc5;
}

.als-1, .als-2 {
  fill-rule: evenodd;
}

.als-2 {
  fill: #fff;
}

@media only screen and (min-width: 620px) and (max-width: 975px) {
  .nav-wrap {
    background: #313131;
    display: block;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .top_head {
    width: 95%;
  }
}

@media only screen and (min-width: 960px) {
  .top_head {
    width: 90%;
  }

  #ahead .top_head li.logotype {
    z-index: 2147483647;
    padding-left: 5%;
  }

  .nav-wrap {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  #ahead .top_head li.logotype {
    padding-left: 5%;
    position: absolute;
  }

  #ahead .top_head li:not(.logotype), .log-out {
    display: none;
  }
}

/*# sourceMappingURL=index.aa87ea76.css.map */
